import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../../assest/logo.png'
import product from '../../assest/product.png'
import headerRate from '../../data/headerRate.json';
import './Header.scss'
import { faArrowRight, faStar, faBagShopping, faFaceSmile } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

const iconMap = {
    faStar: faStar,
    faBagShopping: faBagShopping,
    faFaceSmile: faFaceSmile
};

function Header() {
    const [headerData, setHeaderData] = useState(headerRate);

    return (
        <header className="header container">
            <div className='d-flex justify-content-center'>
                <img src={logo} alt="Logo" className='logo'/>
            </div>
            <div className='information-header'>
                <div className='col-6 left-wrapper d-flex justify-content-end'>
                    <div className='background-circle'>
                        <img src={product} alt="Product1" className='product1'/>
                        <img src={product} alt="Product2" className='product2'/>
                    </div>

                </div>
                <div className='col-6 right-wrapper'>
                    <div>
                        <div className='title'>
                            Kem chống nắng
                        </div>
                        <div className='content-header'>
                            KEM CHỐNG NẮNG ĐA CHỨC NĂNG ENF: TÁI TẠO DA, CẢI THIỆN NẾP NHĂN, LÀM SÁNG DA
                        </div>
                        <div className='bottom'>
                            <div className='button-wrapper'>
                                <button className='bottom-button'>Tìm hiểu thêm <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon> </button>
                            </div>
                            <div className='rate d-flex justify-content-between'>
                                {
                                    headerData.map((data, index) => (
                                        <div key={index} >
                                            <div className='d-flex align-items-center'>
                                                <FontAwesomeIcon icon={iconMap[data.icon]} className='icon' /> 
                                                <h2>{data.point}</h2>
                                            </div>
                                            <p>
                                                {data.text}
                                            </p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </header>
    );
}

export default Header;