import Copyright from "./Copyright/Copyright";
import Information from "./Information/Information";
import './Footer.scss';

function Footer() {
    return (
        <footer>
            <Information></Information>
            <Copyright></Copyright>
        </footer>
    );
}

export default Footer;