import './Information.scss';
import information from '../../../assest/information.png';
import footerbranch from '../../../assest/footerbranch.png';

function Information() {
    function Submit(e) {
        e.preventDefault();
        const formEle = document.querySelector(".form-spreedsheet");
        const formDatab = new FormData(formEle);
        fetch(
          "https://script.google.com/macros/s/AKfycbyPrC2gg7726qq3cEZy_5QqAT7imBALQB3rfk6BWwuYZAFB2ISGsVDD-Nh6mSQWpiaInQ/exec",
          {
            method: "POST",
            body: formDatab
          }
        )
          .then((res) => res.json())
          .then((data) => {
            alert("Your information was successfully sent to the Google Sheets!");
            console.log(data);
          })
          .catch((error) => {
            alert("Your information was successfully sent to the Google Sheets!");
            console.log(error);
          });
      }

    return (
        <div className="information">
            <img src={footerbranch} className='footer-branch' alt='Footer Branch' />
            <div className='d-flex justify-content-between information-wrapper'>
                <img src={information} alt='Information' />
                <div className='content'>
                    <h2>Để lại thông tin mua hàng</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                    <form className="form-spreedsheet" onSubmit={Submit}>
                        <label>Họ Tên <span style={{color: 'red'}}>*</span></label>
                        <input type='text' className='form-control' name='Name' required />
                        <label>Số điện thoại <span style={{color: 'red'}}>*</span></label>
                        <input type='text' className='form-control' name='Phone' required />
                        <div className='d-flex justify-content-center button-wrapper'>
                            <button type='submit' className='button-details'>Đặt hàng ngay </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Information;
