import './Authenticate.scss';
import vector from '../../../assest/Vector1.png';
import authenticatebtm from '../../../assest/authenticatebtm.png';
import authenticateData from '../../../data/authenticate.json';
import arrowright from '../../../assest/arrowright.png';
import arrowleft from '../../../assest/arrowleft.png';
import { useState } from 'react';

function Authenticate() {
    const [authenticateDatas] = useState(authenticateData);
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % authenticateDatas.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + authenticateDatas.length) % authenticateDatas.length);
    };

    return (
        <>
            <div className='authenticate'>
                <div className='authenticate-text container'>
                    <h2>Lời chứng thực</h2>
                    <img src={vector} alt="Vector" />
                    <h5>Lorem Ipsum chỉ đơn giản là văn bản giả của ngành in ấn và sắp chữ. Lorem Ipsum là người đứng đầu ngành</h5>
                </div>
                <div className='container authenticate-list'>
                    {authenticateDatas.map((data, index) => (
                        <div
                            key={index}
                            className={`block-wrapper ${currentSlide === index ? 'active' : ''}`}
                        >
                            <div className='d-flex'>
                                <img src={require(`../../../assest/${data.avatar}`)} alt={data.name} />
                                <div className='infor-wrapper'>
                                    <h3>{data.name}</h3>
                                    <h5>{data.company}</h5>
                                </div>
                            </div>
                            <p>{data.text}</p>
                        </div>
                    ))}
                </div>
                <div className="slide-controls">
                    <button className='prevbtn' onClick={prevSlide}><img src={arrowleft}/></button>
                    <button className='nextbtn' onClick={nextSlide}><img src={arrowright}/></button>
                </div>
            </div>
            <div className='authenticate-bottom'>
                <img src={authenticatebtm} alt='Authenticate Bot' />
            </div>
        </>
    );
}

export default Authenticate;
