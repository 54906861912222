import React, { useState } from 'react';
import vector from '../../../assest/Vector.png';
import mainChooseMe from '../../../data/mainChooseMe.json';
import chooseme2 from '../../../assest/chooseme2update.png';
import box1 from '../../../assest/box1.png';
import box2 from '../../../assest/box2.png';
import './ChooseMe.scss';

function ChooseMe() {
    const [chooseMeData] = useState(mainChooseMe);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState('');

    const slides = [box1, box2];

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
    };

    const openModal = (image) => {
        setModalImage(image);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className='choose-me-1 container'>
                <div className='main-text'>
                    <h2>Tại sao chọn chúng tôi</h2>
                    <img src={vector} alt="Vector" />
                    <h5>KEM CHỐNG NẮNG ĐA CHỨC NĂNG ENF: TÁI TẠO DA, CẢI THIỆN NẾP NHĂN, LÀM SÁNG DA</h5>
                </div>
                <div className='d-flex justify-content-around' style={{ flexWrap: 'wrap' }}>
                    {chooseMeData.map((data, index) => (
                        <div key={index} className='block-wrapper'>
                            <div className='circle'>
                                <img src={require(`../../../assest/chooseMeIcon/${data.icon}`)} alt={data.title} />
                            </div>
                            <h3>{data.title}</h3>
                            <p>{data.content}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className='choose-me-2'>
                <div className='d-flex choose-me-2-wrapper'>
                    <img src={chooseme2} alt="logo" className='big-img' />
                    <div className='box-wrapper'>
                        <h3>Tính năng chính của Kem chống nắng ENF</h3>
                        <p>
                            1. Bảo vệ da khỏi ánh nắng mặt trời: Là màng lọc tia UVA/UVB hoàn hảo, với chỉ số chống nắng SPF 47 / PA+++
                            <br />
                            2. Thành phần ba chức năng:
                            <br />
                            - Tái tạo da
                            <br />
                            - Cải thiện nếp nhăn
                            <br />
                            - Làm trắng da
                            <br />
                            Kem chống nắng ENF không chỉ bảo vệ da hoàn hảo mà còn bổ sung các thành phần dưỡng da, làm sáng, phục hồi làn da bị tổn thương.
                        </p>
                        <div className='img-wrapper'>
                            {slides.map((slide, index) => (
                                <img
                                    key={index}
                                    src={slide}
                                    alt={`Slide ${index + 1}`}
                                    className={`slide ${currentSlide === index ? 'active' : ''}`}
                                    onClick={() => openModal(slide)}
                                />
                            ))}
                            <button className="prev" onClick={prevSlide}>&#10094;</button>
                            <button className="next" onClick={nextSlide}>&#10095;</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="modal show" onClick={closeModal}>
                    <span className="close" onClick={closeModal}>&times;</span>
                    <div className="modal-content">
                        <img src={modalImage} alt="Full Screen" />
                    </div>
                </div>
            )}
        </>
    );
}

export default ChooseMe;
