import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../../assest/logo2.png'
import { faFacebook, faInstagram, faTelegram } from '@fortawesome/free-brands-svg-icons';
import './Copyright.scss';

function Copyright() {
    return (
        <div className="copy-right">
            <div className='container'>
                <img src={logo} alt="Logo"/>
                <div className='content'>
                    <h3>Mỹ phẩm</h3>
                    <div className='social-media d-flex justify-content-center'>
                        <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
                        <FontAwesomeIcon icon={faTelegram}></FontAwesomeIcon>
                        <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
                    </div>
                    <p>© 2022 Convertio chủ đề của UiCore. Mọi quyền được bảo lưu.</p>
                </div>
            </div>
            
        </div>
    )
}

export default Copyright;