import Authenticate from './Authenticate/Authenticate';
import ChooseMe from './ChooseMe/ChooseMe';
import ForYou from './ForYou/ForYou';
import './Main.scss'
import Recipe from './Recipe/Recipe';


function Main() {
    return (
        <main className="main">
            <ChooseMe></ChooseMe>
            <Recipe></Recipe>
            <ForYou></ForYou>
            <Authenticate></Authenticate>
        </main>
    )
}

export default Main;