import './App.css';
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './layout/Header/Header';
import Main from './layout/Main/Main';
import Footer from './layout/Footer/Footer';

function App() {
  return (
      <>
        <Header></Header>
        <Main></Main>
        <Footer></Footer>
      </>
  );
}

export default App;
