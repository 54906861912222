import './ForYou.scss';
import foryoubranch from '../../../assest/foryoubranch.png';
import foryoubranch2 from '../../../assest/foryoubranch2.png';
import foryou from '../../../assest/foryoubackground.png';
import foryou2 from '../../../assest/Foryou2.png';
function ForYou() {
    return (
        <>
        <div className="for-you ">
            <div className='left-wrapper'>
                <h2>Sản phẩm nào dành cho bạn?</h2>
                <p>
                1.     Bảo vệ da khỏi ánh nắng mặt trời: Là màng lọc tia UVA/UVB hoàn hảo, với chỉ số chống nắng SPF 47 / PA+++
                <br />
                2.     Thành phần ba chức năng:
                <br />
                -       Tái tạo da
                <br />
                -       Cải thiện nếp nhăn
                <br />
                -       Làm trắng da
                <br />
                Kem chống nắng ENF không chỉ bảo vệ da hoàn hảo mà còn bổ sung các thành phần dưỡng da, làm sáng, phục hồi làn da bị tổn thương.
                </p>
            </div>
        </div>
        <div className='for-you-bot-wrapper'>
            <img src={foryoubranch} className='for-you-2-branch' alt='For you Branch' />
            <img src={foryoubranch2} className='for-you-2-branch-2' alt='For you Branch' />
            <img src={foryou2} className='for-you-2-background' alt="For you 2"/>
        </div>
    </>
    );
}

export default ForYou;
