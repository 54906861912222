import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Recipe.scss';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import recipe from '../../../assest/recipe.png';
import recipebranch from '../../../assest/recipebranch.png';
import recipebranch2 from '../../../assest/recipebranch2.png';

function Recipe() {
    const [expandedItem, setExpandedItem] = useState(-1);
    const contentRefs = useRef([]);

    const toggleExpand = (index) => {
        setExpandedItem((prev) => (prev === index ? -1 : index));
    };

    const items = [
        'FGF: Giúp tái tạo và cải thiện độ đàn hồi cho da',
        'EGF: Cải thiện độ đàn hồi của da',
        'Borphyrin',
        '7 loại peptide'
    ];

    const itemDetails = [
        `<p>
            <b>&bull; Tái tạo mô và chữa lành tổn thương:</b> FGF thúc đẩy phục hồi da và mô tổn thương.<br />
            <b>&bull; Tăng cường lưu thông máu:</b> FGF khuyến khích hình thành mạch máu mới và cung cấp oxy cho tế bào, giúp da sáng hồng và rạng rỡ.
        </p>`,
        `<p>
            <b>&bull; Tái tạo tế bào:</b> EGF thúc đẩy tái tạo và tăng trưởng tế bào da.<br />
            <b>&bull; Sản xuất collagen:</b> EGF kích thích tổng hợp collagen, tạo ra độ đàn hồi cho da.<br />
            <b>&bull; Dưỡng ẩm:</b> EGF cải thiện và duy trì độ ẩm cho làn da.<br />
            <b>&bull; Chống viêm và phục hồi thương tổn:</b> EGF giảm viêm và làm dịu da tổn thương, như mụn trứng cá hoặc cháy nắng.<br />
            <b>&bull; Cải thiện sắc tố da:</b> Điều chỉnh sản xuất melanin để làm đều màu và dưỡng trắng da.<br />
            <b>&bull; Cải thiện các bệnh về da:</b> Hỗ trợ điều trị các bệnh da liễu mãn tính như viêm da dị ứng, bệnh vảy nến.
        </p>`,
        `<p>
            <b>&bull; Tăng cường độ đàn hồi cho da:</b> Kích thích tế bào mỡ dưới da để tăng cường độ đàn hồi.<br />
            <b>&bull; Cải thiện đường nét:</b> Làm đầy và săn chắc da, cải thiện đường nét khuôn mặt.<br />
            <b>&bull; Giảm nếp nhăn:</b> Ngăn ngừa hình thành nếp nhăn, làm săn chắc da chảy xệ.<br />
            <b>&bull; Dưỡng ẩm:</b> Cung cấp độ ẩm hiệu quả cho da.
        </p>`,
        `<p>
            <b>&bull; Collagen peptide:</b> Ức chế phân hủy collagen và kích thích tăng sinh collagen, sợi đàn hồi, proteoglycan, glycosaminoglycan.<br />
            <b>&bull; Elastin peptide:</b> Tăng độ đàn hồi và săn chắc da.<br />
            <b>&bull; Matrix peptide:</b> Tăng cường năng suất của da.
        </p>`
    ];

    return (
        <div className="container recipe">
            <img src={recipebranch} className='recipe-branch' />
            <img src={recipebranch2} className='recipe-branch-2' />
            <div className='receipe-wrapper d-flex'>
                <div className='col-6 right-wrapper'>
                    {items.map((item, index) => (
                        <div key={index} className='item'>
                            <div className='d-flex justify-content-between item-wrapper' onClick={() => toggleExpand(index)}>
                                <h4>{item}</h4>
                                <FontAwesomeIcon className='icon' icon={expandedItem === index ? faChevronUp : faChevronDown} />
                            </div>
                            <div
                                ref={el => contentRefs.current[index] = el}
                                className={`p-content ${expandedItem === index ? 'expanded' : ''}`}
                                style={{
                                    height: expandedItem === index ? `${contentRefs.current[index].scrollHeight}px` : '0'
                                }}
                            >
                                <div className='mt-2' dangerouslySetInnerHTML={{ __html: itemDetails[index] }}></div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='col-6 left-wrapper'>
                    <img src={recipe} alt='Recipe' />
                </div>
            </div>
        </div>
    );
}

export default Recipe;
